import { ComponentWithAs, createIcon, IconProps } from "@chakra-ui/react";

export type ReleaseIcon = ComponentWithAs<"svg", IconProps>;

export const BracketErrorIcon = createIcon({
  displayName: "BracketError",
  viewBox: "2 2 36 36",
  path: [
    <path
      key={0}
      d="M11.663 33.3389C9.36083 33.3389 7.49457 31.4726 7.49457 29.1705V24.1684C7.49457 23.0629 7.0554 22.0026 6.27368 21.2209C5.49195 20.4392 4.4317 20 3.32617 20C4.4317 20 5.49195 19.5608 6.27368 18.7791C7.0554 17.9974 7.49457 16.9371 7.49457 15.8316V10.8295C7.49457 8.52737 9.36083 6.66112 11.663 6.66112"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2.5"
    />,
    <path
      key={1}
      d="M22.3141 18.1465L17.6846 22.7788"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2.5"
    />,
    <path
      key={2}
      d="M17.6836 18.1479L22.3159 22.7774"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2.5"
    />,
    <path
      key={3}
      d="M28.3359 6.66112C30.6381 6.66112 32.5043 8.52737 32.5043 10.8295V15.8316C32.5043 18.1337 34.3706 20 36.6727 20C34.3706 20 32.5043 21.8663 32.5043 24.1684V29.1705C32.5043 31.4726 30.6381 33.3389 28.3359 33.3389"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2.5"
    />,
  ],
});

export const BracketSuccessIcon = createIcon({
  displayName: "BracketSuccess",
  viewBox: "2 2 36 36",
  path: [
    <path
      key={0}
      d="M11.663 33.3389C9.36083 33.3389 7.49457 31.4726 7.49457 29.1705V24.1684C7.49457 23.0629 7.0554 22.0026 6.27368 21.2209C5.49195 20.4392 4.4317 20 3.32617 20C4.4317 20 5.49195 19.5608 6.27368 18.7791C7.0554 17.9974 7.49457 16.9371 7.49457 15.8316V10.8295C7.49457 8.52737 9.36083 6.66112 11.663 6.66112"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2.5"
    />,
    <path
      key={1}
      d="M28.3359 6.66112C30.6381 6.66112 32.5043 8.52737 32.5043 10.8295V15.8316C32.5043 18.1337 34.3706 20 36.6727 20C34.3706 20 32.5043 21.8663 32.5043 24.1684V29.1705C32.5043 31.4726 30.6381 33.3389 28.3359 33.3389"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2.5"
    />,
    <path
      key={2}
      d="M23.7032 18.1465L19.0737 22.7788L16.2949 20"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2.5"
    />,
  ],
});

export const BuildingTemplateIcon = createIcon({
  displayName: "Building Template",
  viewBox: "2 2 36 36",
  path: [
    <path
      key={0}
      d="M11.667 27.5H18.3337"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2.5"
    />,
    <path
      key={1}
      d="M29.167 25.9583L26.0837 29.0417L24.167 27.1233"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2.5"
    />,
    <rect
      key={2}
      fill="none"
      height="30"
      rx="4"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2.5"
      width="30"
      x="5"
      y="5"
    />,
    <path
      key={3}
      d="M25.0003 21.6667H11.667"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2.5"
    />,
    <path
      key={4}
      d="M13.3337 11.6667L11.667 13.3333L13.3337 15"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2.5"
    />,
    <path
      key={5}
      d="M18.333 11.6667L19.9997 13.3333L18.333 15"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2.5"
    />,
  ],
});

export const RepoForkedIcon = createIcon({
  displayName: "Repo Forked",
  viewBox: "2 2 36 36",
  path: [
    <path
      key={0}
      d="M20 35H13.3333C8.73096 35 5 31.269 5 26.6667V13.3333C5 8.73096 8.73096 5 13.3333 5H26.6667C31.269 5 35 8.73096 35 13.3333V20"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2.5"
    />,
    <path
      key={1}
      d="M28.333 29.7917L29.5841 31.0417L31.6663 28.9583"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2.5"
    />,
    <ellipse
      key={2}
      cx="29.9997"
      cy="30"
      fill="none"
      rx="6.66667"
      ry="6.66667"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2.5"
    />,
    <circle
      key={3}
      cx="25.833"
      cy="14.1667"
      fill="none"
      r="2.5"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2.5"
    />,
    <ellipse
      key={4}
      cx="15.833"
      cy="13.3333"
      fill="none"
      rx="2.5"
      ry="2.5"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2.5"
    />,
    <ellipse
      key={5}
      cx="15.833"
      cy="26.6667"
      fill="none"
      rx="2.5"
      ry="2.5"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2.5"
    />,
    <path
      key={6}
      d="M15.8333 15.8333V24.1667"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2.5"
    />,
    <path
      key={7}
      d="M15.833 24.1667V24.1667C15.833 22.0956 17.5119 20.4167 19.583 20.4167H22.083C24.1541 20.4167 25.833 18.7377 25.833 16.6667V16.6667"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2.5"
    />,
  ],
});

export const SaveIcon = createIcon({
  displayName: "Save",
  viewBox: "2 2 36 36",
  path: [
    <path
      key={0}
      clipRule="evenodd"
      d="M30 35H25V29.8333C25 29.3731 25.3731 29 25.8333 29H29.1667C29.6269 29 30 29.3731 30 29.8333V35Z"
      fill="none"
      fillRule="evenodd"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2.5"
    />,
    <path
      key={1}
      clipRule="evenodd"
      d="M20.7322 23.4344L23.4344 20.7322C23.9033 20.2634 24.5392 20 25.2022 20H32.5C33.8807 20 35 21.1193 35 22.5V32.5C35 33.8807 33.8807 35 32.5 35H22.5C21.1193 35 20 33.8807 20 32.5V25.2022C20 24.5392 20.2634 23.9033 20.7322 23.4344V23.4344Z"
      fill="none"
      fillRule="evenodd"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2.5"
    />,
    <path
      key={2}
      d="M11.4814 17.315H17.3148V10.185"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2.5"
    />,
    <path
      key={3}
      d="M28.201 15C27.3455 9.05113 22.1083 4.72716 16.105 5.0132C10.1018 5.29924 5.29944 10.1016 5.0134 16.1048C4.72736 22.1081 9.05133 27.3453 15.0002 28.2008"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2.5"
    />,
  ],
});

export const ConfigurationIcon = createIcon({
  displayName: "Configuration",
  viewBox: "0 0 24 25",
  path: [
    <path
      key={0}
      d="M3 8.0401H21"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />,
    <path
      key={1}
      d="M11.0049 5.5301L10.9999 5.5351L11.0049 5.5401L11.0099 5.5351L11.0049 5.5301"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />,
    <path
      key={2}
      d="M8.50488 5.5301L8.49988 5.5351L8.50488 5.5401L8.50988 5.5351L8.50488 5.5301"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />,
    <path
      key={3}
      d="M6.00488 5.5301L5.99988 5.5351L6.00488 5.5401L6.00988 5.5351L6.00488 5.5301"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />,
    <path
      key={4}
      d="M8 20.0401H5C4.46952 20.0402 3.96073 19.8296 3.58563 19.4545C3.21052 19.0794 2.99985 18.5706 3 18.0401V5.0401C2.99985 4.50962 3.21052 4.00083 3.58563 3.62573C3.96073 3.25062 4.46952 3.03995 5 3.0401H19C19.5305 3.03995 20.0393 3.25062 20.4144 3.62573C20.7895 4.00083 21.0001 4.50962 21 5.0401V9.0401"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />,
    <path
      key={5}
      d="M14 15.0401L15.5 16.5401L14 18.0401"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />,
    <path
      key={6}
      d="M17.5 19.0401H19"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />,
    <rect
      key={7}
      fill="none"
      height="10"
      rx="2"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      width="11"
      x="11"
      y="12.0401"
    />,
  ],
});

export const ArrowLeftSmallIcon = createIcon({
  displayName: "Arrow Left Small",
  viewBox: "-3 -4 24 24",
  path: [
    <path
      key={0}
      d="M3.33366 8H12.667"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />,
    <path
      key={1}
      d="M6.66699 11.3333L3.33366 8"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />,
    <path
      key={2}
      d="M6.66699 4.66667L3.33366 8"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />,
  ],
});

export const ArrowLeftIcon = createIcon({
  displayName: "Arrow Left",
  viewBox: "0 0 24 25",
  path: [
    <path
      key={0}
      d="M5 12.0401H19"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />,
    <path
      key={1}
      d="M10 7.0401L5 12.0401"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />,
    <path
      key={2}
      d="M10 17.0401L5 12.0401"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />,
  ],
});

export const ArrowRightIcon = createIcon({
  displayName: "Arrow Right",
  viewBox: "0 0 24 25",
  path: [
    <path
      key={0}
      d="M19 12.0401H5"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />,
    <path
      key={1}
      d="M14 17.0401L19 12.0401"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />,
    <path
      key={2}
      d="M14 7.0401L19 12.0401"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />,
  ],
});

export const StepPendingIcon = createIcon({
  displayName: "StepPending",
  viewBox: "0 0 16 16",
  path: [
    <path
      key={0}
      clipRule="evenodd"
      d="M8 5a3 3 0 1 0 0 6a3 3 0 0 0 0-6Z"
      fill="currentColor"
      fillRule="evenodd"
    />,
  ],
});

export const StepFailedIcon = createIcon({
  displayName: "StepFailed",
  viewBox: "0 0 16 16",
  path: [
    <path
      key={0}
      d="M6 6L10 10M6 10L10 6"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.0"
    />,
  ],
});

export const StepWarningIcon = createIcon({
  displayName: "StepWarning",
  viewBox: "-6 -6 36 36",
  path: [
    <path
      key={0}
      d="M13 17.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0Zm-.25-8.25a.75.75 0 0 0-1.5 0v4.5a.75.75 0 0 0 1.5 0v-4.5Z"
      fill="currentColor"
      stroke="currentColor"
    />,
    <path
      key={1}
      d="M9.836 3.244c.963-1.665 3.365-1.665 4.328 0l8.967 15.504c.963 1.667-.24 3.752-2.165 3.752H3.034c-1.926 0-3.128-2.085-2.165-3.752Zm3.03.751a1.002 1.002 0 0 0-1.732 0L2.168 19.499A1.002 1.002 0 0 0 3.034 21h17.932a1.002 1.002 0 0 0 .866-1.5L12.866 3.994Z"
      fill="currentColor"
      stroke="currentColor"
    />,
  ],
});

export const StepCompleteIcon = createIcon({
  displayName: "StepComplete",
  viewBox: "0 0 16 16",
  path: [
    <path
      key={0}
      d="M5.6 8.2L7.1 9.9L10.4 6.4"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.0"
    />,
  ],
});

export const StepRunningIcon = createIcon({
  displayName: "StepRunning",
  viewBox: "0 0 16 16",
  defaultProps: {
    className: "anim-rotate",
  },
  path: [
    <path
      key={0}
      d="M4 4a4 4 0 1 1 8 8 4 4 0 0 1-8-8Z"
      fill="none"
      opacity=".5"
      stroke="currentColor"
      strokeWidth="1.3"
    />,
    <path
      key={1}
      clipRule="evenodd"
      d="M8 5a3 3 0 1 0 0 6a3 3 0 0 0 0-6Z"
      fill="currentColor"
      fillRule="evenodd"
    />,
    <path key={2} d="M13 8a5 5 0 0 0-5-5V2a6 6 0 0 1 6 6h-2Z" fill="currentColor" />,
  ],
});

export const CircleCheckmarkIcon = createIcon({
  displayName: "CircleCheckmark",
  viewBox: "0 0 24 25",
  path: [
    <circle
      key={0}
      cx="12.0399"
      cy="12"
      fill="none"
      r="9.00375"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />,
    <path
      key={1}
      d="M8.48242 12.3391L10.6503 14.507L10.6363 14.493L15.5274 9.60199"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />,
  ],
});

export const ChevronDownIcon = createIcon({
  displayName: "Chevron Down",
  viewBox: "2 2 20 20",
  path: (
    <path
      d="M8 10.0401L12 14.0401L16 10.0401"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />
  ),
});

export const ChevronUpIcon = createIcon({
  displayName: "Chevron Up",
  viewBox: "2 2 20 20",
  path: (
    <path
      d="M16 14L12 10L8 14"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />
  ),
});

export const ClusterIcon = createIcon({
  displayName: "Cluster",
  viewBox: "0 0 24 25",
  path: [
    <path
      key={0}
      d="M3 8.0401H21"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />,
    <path
      key={1}
      clipRule="evenodd"
      d="M21.5 20.1741L18.5 21.9061C18.1906 22.0847 17.8094 22.0847 17.5 21.9061L14.5 20.1741C14.1906 19.9955 14 19.6654 14 19.3081V15.7721C14 15.4148 14.1906 15.0847 14.5 14.9061L17.5 13.1741C17.8094 12.9955 18.1906 12.9955 18.5 13.1741L21.5 14.9061C21.8094 15.0847 22 15.4148 22 15.7721V19.3081C22 19.6654 21.8094 19.9955 21.5 20.1741Z"
      fill="none"
      fillRule="evenodd"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />,
    <path
      key={2}
      d="M21.8658 15.2721L18.4998 17.2155C18.1904 17.3942 17.8092 17.3942 17.4998 17.2155L14.1338 15.2721"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />,
    <path
      key={3}
      d="M18 22.0401V17.3486"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />,
    <path
      key={4}
      d="M11 5.5301L10.995 5.5351L11 5.5401L11.005 5.5351L11 5.5301"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />,
    <path
      key={5}
      d="M8.5 5.5301L8.495 5.5351L8.5 5.5401L8.505 5.5351L8.5 5.5301"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />,
    <path
      key={6}
      d="M6 5.5301L5.995 5.5351L6 5.5401L6.005 5.5351L6 5.5301"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />,
    <path
      key={7}
      d="M10 20.0401H5C4.46952 20.0402 3.96073 19.8296 3.58563 19.4545C3.21052 19.0794 2.99985 18.5706 3 18.0401V5.0401C2.99985 4.50962 3.21052 4.00083 3.58563 3.62573C3.96073 3.25062 4.46952 3.03995 5 3.0401H19C19.5305 3.03995 20.0393 3.25062 20.4144 3.62573C20.7895 4.00083 21.0001 4.50962 21 5.0401V10.0401"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />,
  ],
});

export const CopyIcon = createIcon({
  displayName: "Copy",
  viewBox: "0 0 24 25",
  path: [
    <path
      key={0}
      clipRule="evenodd"
      d="M11 15.0401C9.89543 15.0401 9 14.1447 9 13.0401V5.0401C9 3.93553 9.89543 3.0401 11 3.0401H19C20.1046 3.0401 21 3.93553 21 5.0401V13.0401C21 14.1447 20.1046 15.0401 19 15.0401H11Z"
      fill="none"
      fillRule="evenodd"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />,
    <path
      key={1}
      d="M15 18.0401V19.0401C15 20.1447 14.1046 21.0401 13 21.0401H5C3.89543 21.0401 3 20.1447 3 19.0401V11.0401C3 9.93553 3.89543 9.0401 5 9.0401H6"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />,
  ],
});

export const CreateIcon = createIcon({
  displayName: "Create",
  viewBox: "0 0 24 24",
  path: [
    <path
      key={0}
      d="M9 16H5C3.34315 16 2 14.6569 2 13V6C2 4.34315 3.34315 3 5 3H19C20.6569 3 22 4.34315 22 6V12"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />,
    <path
      key={1}
      clipRule="evenodd"
      d="M15 16.9998L14.4145 16.4143C14.1493 16.149 13.7895 16 13.4144 16C13.0393 16 12.6795 16.149 12.4143 16.4143V16.4143C11.9376 16.891 11.8636 17.6379 12.2375 18.1988L14.1775 21.1093C14.5485 21.6658 15.173 22 15.8418 22H19.6378C20.6465 22 21.4973 21.2489 21.6224 20.248L21.9843 17.3512C22.1177 16.284 21.3831 15.3024 20.3217 15.1293L18 14.7507V12.5C18 11.6716 17.3284 11 16.5 11V11C15.6715 11 15 11.6716 15 12.5V16.9998Z"
      fill="none"
      fillRule="evenodd"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />,
    <path
      key={2}
      d="M6 7.5L7.5 9L6 10.5"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />,
    <path
      key={3}
      d="M9.5 11.5H11"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />,
  ],
});

export const CrossIcon = createIcon({
  displayName: "Cross",
  viewBox: "2 2 20 20",
  path: [
    <path
      key={0}
      d="M8 8.0401L16 16.0401"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />,
    <path
      key={1}
      d="M16 8.0401L8 16.0401"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />,
  ],
});

export const DashboardIcon = createIcon({
  displayName: "Dashboard",
  viewBox: "0 0 24 24",
  path: [
    <path
      key={0}
      clipRule="evenodd"
      d="M5 3H19C20.105 3 21 3.895 21 5V19C21 20.105 20.105 21 19 21H5C3.895 21 3 20.105 3 19V5C3 3.895 3.895 3 5 3Z"
      fill="none"
      fillRule="evenodd"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />,
    <path
      key={1}
      d="M10 3V21"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />,
    <path
      key={2}
      d="M21 12H10"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />,
  ],
});

export const DatasetsIcon = createIcon({
  displayName: "Datasets",
  viewBox: "0 0 40 40",
  path: [
    <path
      key={1}
      d="M20 20C27.5939 20 33.75 16.6421 33.75 12.5C33.75 8.35786 27.5939 5 20 5C12.4061 5 6.25 8.35786 6.25 12.5C6.25 16.6421 12.4061 20 20 20Z"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />,
    <path
      key={2}
      d="M6.25 12.5V20C6.25 24.1422 12.4063 27.5 20 27.5C27.5937 27.5 33.75 24.1422 33.75 20V12.5"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />,
    <path
      key={3}
      d="M6.25 20V27.5C6.25 31.6422 12.4063 35 20 35C27.5937 35 33.75 31.6422 33.75 27.5V20"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />,
  ],
});

// TODO Rename and re-use this if we want
export const OldDatasetsIcon = createIcon({
  displayName: "OldDatasets",
  viewBox: "0 0 24 25",
  path: [
    <path
      key={0}
      d="M8.49888 11.5399L6.49805 9.53906L8.49888 7.53823"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />,
    <path
      key={1}
      d="M11.5 7.53823L13.5008 9.53906L11.5 11.5399"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />,
    <path
      key={2}
      d="M17.0026 8.03844H19.0035C20.1085 8.03844 21.0043 8.93424 21.0043 10.0393V19.043C21.0043 20.148 20.1085 21.0439 19.0035 21.0439H7.99888C6.89385 21.0439 5.99805 20.148 5.99805 19.043V16.0418"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />,
    <path
      key={3}
      clipRule="evenodd"
      d="M15.0011 16.0418H4.99693C3.8919 16.0418 2.99609 15.146 2.99609 14.0409V5.03719C2.99609 3.93216 3.8919 3.03635 4.99693 3.03635H15.0011C16.1061 3.03635 17.0019 3.93216 17.0019 5.03719V14.0409C17.0019 15.146 16.1061 16.0418 15.0011 16.0418Z"
      fill="none"
      fillRule="evenodd"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />,
  ],
});

export const DeployRocketIcon = createIcon({
  displayName: "Deploy Rocket",
  viewBox: "0 0 24 25",
  path: [
    <path
      key={0}
      d="M12.9401 11.1001L5.87012 18.1701"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />,
    <path
      key={1}
      d="M6.63344 13.0391L3.17044 12.2211C2.78944 12.1311 2.65544 11.6581 2.93144 11.3811L5.68544 8.6271C5.87044 8.4421 6.12144 8.3361 6.38444 8.3341L9.48944 8.3071"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />,
    <path
      key={2}
      clipRule="evenodd"
      d="M19.8791 9.01509L20.6871 5.12709C20.9071 4.06909 19.9711 3.13309 18.9131 3.35309L15.0251 4.16109C13.8801 4.39909 12.8301 4.96609 12.0041 5.79309L8.81407 8.98209C7.59707 10.1991 6.80507 11.7771 6.55507 13.4801L6.54407 13.5531C6.38607 14.6401 6.75007 15.7371 7.52607 16.5141V16.5141C8.30207 17.2901 9.40007 17.6541 10.4871 17.4951L10.5601 17.4841C12.2631 17.2351 13.8411 16.4421 15.0581 15.2251L18.2471 12.0361C19.0741 11.2101 19.6411 10.1601 19.8791 9.01509V9.01509Z"
      fill="none"
      fillRule="evenodd"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />,
    <path
      key={3}
      d="M13.4004 4.7541C15.7114 6.3301 17.7104 8.3291 19.2864 10.6401"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />,
    <path
      key={4}
      d="M11 17.4071L11.818 20.8701C11.908 21.2511 12.381 21.3851 12.658 21.1091L15.412 18.3551C15.597 18.1701 15.703 17.9191 15.705 17.6561L15.732 14.5511"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />,
  ],
});

export const DocumentationIcon = createIcon({
  displayName: "Documentation",
  viewBox: "0 0 24 25",
  path: [
    <path
      key={0}
      d="M12 12.0401H8"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />,
    <path
      key={1}
      d="M12 16.0401H17"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />,
    <path
      key={2}
      d="M7 16.0401H9"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />,
    <path
      key={3}
      d="M17 12.0401H15"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />,
    <path
      key={4}
      d="M17 8.0401H13"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />,
    <path
      key={5}
      d="M3 10.0401V18.0401C3 19.697 4.34315 21.0401 6 21.0401H18C19.6569 21.0401 21 19.697 21 18.0401V6.0401C21 4.38325 19.6569 3.0401 18 3.0401H13"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />,
    <path
      key={6}
      d="M5 7.0401L3 5.0401L5 3.0401"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />,
    <path
      key={7}
      d="M8 3.0401L10 5.0401L8 7.0401"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />,
  ],
});

export const DownloadIcon = createIcon({
  displayName: "Download",
  viewBox: "0 0 24 26",
  path: [
    <path
      key={0}
      d="M15.5573 11.6137L11.9999 15.3194L8.44238 11.6137"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />,
    <path
      key={1}
      d="M12.0002 4.2033V15.3194"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />,
    <path
      key={2}
      d="M20.0037 17.1711C20.0037 19.2177 18.411 20.8768 16.4463 20.8768H7.55455C5.58981 20.8768 3.99707 19.2177 3.99707 17.1711"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />,
  ],
});

export const EnvHandlesIcon = createIcon({
  displayName: "Env Handles",
  viewBox: "0 0 24 25",
  path: [
    <path
      key={0}
      d="M19.0031 8.03843H15.0014C14.4489 8.03843 14.001 7.59053 14.001 7.03802V3.03635"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />,
    <path
      key={1}
      d="M4.99707 18.0426V19.043C4.99707 20.148 5.89287 21.0438 6.9979 21.0438H17.0021C18.1071 21.0438 19.0029 20.148 19.0029 19.043V7.86636C19.0029 7.33575 18.7919 6.82691 18.4167 6.45177L15.5875 3.62259C15.2123 3.24734 14.7035 3.03635 14.1729 3.03635H6.9979C5.89287 3.03635 4.99707 3.93215 4.99707 5.03718V8.03843"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />,
    <path
      key={2}
      d="M4.99693 15.0414L2.99609 13.0405L4.99693 11.0397"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />,
    <path
      key={3}
      d="M7.99805 11.0397L9.99888 13.0405L7.99805 15.0414"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />,
  ],
});

export const TerminalIcon = createIcon({
  displayName: "Terminal",
  viewBox: "0 0 24 24",
  path: [
    <rect
      key={0}
      fill="none"
      height="18"
      rx="5"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      width="18"
      x="3"
      y="3"
    />,
    <path
      key={1}
      d="M13 14.5H15"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />,
    <path
      key={2}
      d="M9 9.5L11 11.5L9 13.5"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />,
  ],
});

export const FetchServiceIcon = createIcon({
  displayName: "Fetch Service",
  viewBox: "0 0 24 25",
  path: [
    <path
      key={0}
      d="M21 17.0401V19.0401C21 20.1447 20.1046 21.0401 19 21.0401H17"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />,
    <path
      key={1}
      d="M3 7.0401V5.0401C3 3.93553 3.89543 3.0401 5 3.0401H7"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />,
    <rect
      key={2}
      fill="none"
      height="11"
      rx="2"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      width="11"
      x="3"
      y="10.0401"
    />,
    <path
      key={3}
      d="M10 7.0401V5.0401C10 3.93553 10.8954 3.0401 12 3.0401H19C20.1046 3.0401 21 3.93553 21 5.0401V12.0401C21 13.1447 20.1046 14.0401 19 14.0401H17"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />,
    <path
      key={4}
      d="M6.20801 13.7068L7.57491 15.0737L6.20801 16.4406"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />,
    <path
      key={5}
      d="M9.41699 17.3734H10.792"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />,
    <path
      key={6}
      d="M13 7.04011H18"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />,
    <path
      key={7}
      d="M16 10.0401H18"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />,
  ],
});

export const ForkIcon = createIcon({
  displayName: "Fork",
  viewBox: "0 0 24 25",
  path: [
    <circle
      key={0}
      cx="6.49811"
      cy="5.53739"
      fill="none"
      r="2.50104"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />,
    <circle
      key={1}
      cx="17.502"
      cy="5.53739"
      fill="none"
      r="2.50104"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />,
    <circle
      key={2}
      cx="12.0001"
      cy="18.5428"
      fill="none"
      r="2.50104"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />,
    <path
      key={3}
      d="M6.49805 8.03842V9.03884C6.49805 10.4201 7.6178 11.5399 8.99909 11.5399H15.0016C15.6649 11.5399 16.3011 11.2764 16.7701 10.8073C17.2391 10.3383 17.5026 9.70216 17.5026 9.03884V8.03842"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />,
    <path
      key={4}
      d="M12.0002 16.0418V11.5399"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />,
  ],
});

export const HelpIcon = createIcon({
  displayName: "Help",
  viewBox: "0 0 24 25",
  path: [
    <path
      key={0}
      d="M14.0001 10.539L11.5016 13.0405L10.001 11.5399"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />,
    <path
      key={1}
      clipRule="evenodd"
      d="M11.9998 4.03676C7.02777 4.03676 2.99609 7.39684 2.99609 11.537C3.07125 14.0353 4.4491 16.3119 6.62761 17.5372C6.41121 18.1252 6.11031 18.6786 5.73437 19.1799C5.52688 19.4829 5.53201 19.8835 5.7472 20.181C5.96238 20.4786 6.34125 20.6089 6.69393 20.5067C7.89676 20.1565 9.02892 19.5982 10.039 18.8572C10.6856 18.9784 11.342 19.0387 11.9998 19.0372C16.9719 19.0372 21.0036 15.6771 21.0036 11.537C21.0036 7.39685 16.9719 4.03676 11.9998 4.03676Z"
      fill="none"
      fillRule="evenodd"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />,
  ],
});

export const HintIcon = createIcon({
  displayName: "Hint",
  viewBox: "0 0 24 25",
  path: [
    <path
      key={0}
      d="M11.9999 13.5407V12.0401C13.1049 12.0401 14.0007 11.1443 14.0007 10.0393C14.0007 8.93424 13.1049 8.03843 11.9999 8.03843C10.8948 8.03843 9.99902 8.93424 9.99902 10.0393"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />,
    <circle
      key={1}
      cx="11.9998"
      cy="12.0401"
      fill="none"
      r="9.00375"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />,
    <path
      key={2}
      d="M12.0002 15.9917C11.9726 15.9917 11.9502 16.0141 11.9502 16.0418C11.9502 16.0694 11.9726 16.0918 12.0002 16.0918C12.0278 16.0918 12.0502 16.0694 12.0502 16.0418C12.0502 16.0141 12.0278 15.9917 12.0002 15.9917"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />,
  ],
});

export const InfoIcon = createIcon({
  displayName: "Info",
  viewBox: "0 0 24 25",
  path: [
    <path
      key={0}
      clipRule="evenodd"
      d="M16.0015 21.0439H7.99818C5.23603 21.0439 2.99609 18.8039 2.99609 16.0418V8.03844C2.99609 5.27629 5.23603 3.03635 7.99818 3.03635H16.0015C18.7637 3.03635 21.0036 5.27629 21.0036 8.03844V16.0418C21.0036 18.8039 18.7637 21.0439 16.0015 21.0439Z"
      fill="none"
      fillRule="evenodd"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />,
    <path
      key={1}
      d="M11.9992 8.03843C11.8612 8.03843 11.7491 8.15048 11.7501 8.28854C11.7501 8.4266 11.8622 8.53864 12.0002 8.53864C12.1383 8.53864 12.2503 8.4266 12.2503 8.28854C12.2503 8.15048 12.1383 8.03843 11.9992 8.03843"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />,
    <path
      key={2}
      d="M12.2504 16.2258V11.4739H11.25"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />,
  ],
});

export const LightbulbIcon = createIcon({
  displayName: "Lightbulb",
  viewBox: "0 0 24 25",
  path: [
    <path
      key={0}
      d="M15 15.2301H9"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />,
    <path
      key={1}
      clipRule="evenodd"
      d="M8.54094 14.9361C6.76494 13.6811 5.69994 11.4901 6.07494 9.0781C6.48094 6.4711 8.65394 4.3901 11.2749 4.0831C14.9129 3.6561 17.9999 6.4881 17.9999 10.0401C17.9999 12.0651 16.9939 13.8521 15.4569 14.9381C15.1809 15.1331 14.9999 15.4351 14.9999 15.7731V18.5401C14.9999 19.9211 13.8809 21.0401 12.4999 21.0401H11.4999C10.1189 21.0401 8.99994 19.9211 8.99994 18.5401V15.7771C8.99994 15.4361 8.81794 15.1321 8.54094 14.9361Z"
      fill="none"
      fillRule="evenodd"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />,
    <path
      key={2}
      d="M20 5.0401L21.19 3.8501"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />,
    <path
      key={3}
      d="M3.99957 15.0401L2.80957 16.2301"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />,
    <path
      key={4}
      d="M4.99957 4.0401L3.80957 2.8501"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />,
    <path
      key={5}
      d="M20 15.0401L21.19 16.2301"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />,
    <path
      key={6}
      d="M21 10.0401H22.69"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />,
    <path
      key={7}
      d="M1.30957 10.0401H2.99957"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />,
    <path
      key={8}
      d="M9 18.0401H14.87"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />,
  ],
});

export const NotificationIcon = createIcon({
  displayName: "Notification",
  viewBox: "0 0 24 25",
  path: [
    <path
      key={0}
      d="M9.56934 19.0501L9.68834 19.3951C10.1003 20.5921 11.4043 21.2281 12.6013 20.8161V20.8161C13.7983 20.4041 14.4343 19.1001 14.0223 17.9031L13.9033 17.5581"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />,
    <path
      key={1}
      d="M10.9852 5.2441L10.6862 4.3761C10.3562 3.4191 9.31321 2.9101 8.35521 3.2401V3.2401C7.39821 3.5701 6.88921 4.6131 7.21821 5.5701L7.51721 6.4381"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />,
    <path
      key={2}
      clipRule="evenodd"
      d="M5.55386 11.8061V11.8061C4.73886 9.4401 5.99686 6.8611 8.36286 6.0461L10.0769 5.4561C12.4429 4.6411 15.0219 5.8991 15.8369 8.2651V8.2651L16.7469 10.9091C16.9199 11.4111 17.2849 11.8231 17.7609 12.0551L18.5759 12.4521C19.0529 12.6851 19.4179 13.0971 19.5899 13.5981V13.5981C19.9299 14.5851 19.4049 15.6611 18.4179 16.0011L8.28286 19.4911C7.29586 19.8311 6.21986 19.3061 5.87986 18.3191V18.3191C5.70686 17.8171 5.74086 17.2681 5.97286 16.7911L6.36986 15.9761C6.60286 15.4991 6.63586 14.9501 6.46286 14.4481L5.55386 11.8061Z"
      fill="none"
      fillRule="evenodd"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />,
  ],
});

export const DeliveryIcon = createIcon({
  displayName: "Delivery",
  viewBox: "0 0 40 40",
  path: [
    <circle
      key={0}
      cx="7"
      cy="14"
      fill="none"
      r="4"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />,
    <circle
      key={1}
      cx="20"
      cy="8"
      fill="none"
      r="4"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />,
    <circle
      key={2}
      cx="33"
      cy="14"
      fill="none"
      r="4"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />,
    <circle
      key={3}
      cx="20"
      cy="32"
      fill="none"
      r="4"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />,
    <path
      key={4}
      d="M20 13V27"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />,
    <path
      key={5}
      d="M20 26.5V25C20 23.3431 21.3431 22 23 22H30C31.6569 22 33 20.6569 33 19V18"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />,
    <path
      key={6}
      d="M20 26.5V25C20 23.3431 18.6569 22 17 22H10C8.34315 22 7 20.6569 7 19V18"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />,
  ],
});

// TODO Rename and re-use this if we want
export const OldDeliveryIcon = createIcon({
  displayName: "OldDelivery",
  viewBox: "0 0 24 25",
  path: [
    <path
      key={0}
      d="M6.55957 12.0401L8.59957 14.0801"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />,
    <path
      key={1}
      d="M8.59957 10.0001L6.55957 12.0401"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />,
    <path
      key={2}
      d="M15.4033 14.0821L17.4443 12.0401L15.4033 9.9981"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />,
    <path
      key={3}
      d="M12.9303 8.5401L11.0703 15.5401"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />,
    <path
      key={4}
      clipRule="evenodd"
      d="M18 21.0401H6C4.343 21.0401 3 19.6971 3 18.0401V6.0401C3 4.3831 4.343 3.0401 6 3.0401H18C19.657 3.0401 21 4.3831 21 6.0401V18.0401C21 19.6971 19.657 21.0401 18 21.0401Z"
      fill="none"
      fillRule="evenodd"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />,
  ],
});

export const PlaceHolderIcon = createIcon({
  displayName: "Placeholder",
  viewBox: "0 0 24 25",
  path: [
    <path
      key={0}
      clipRule="evenodd"
      d="M12 21.0401C16.9706 21.0401 21 17.0107 21 12.0401C21 7.06954 16.9706 3.0401 12 3.0401C7.02944 3.0401 3 7.06954 3 12.0401C3 17.0107 7.02944 21.0401 12 21.0401V21.0401Z"
      fill="none"
      fillRule="evenodd"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />,
    <path
      key={1}
      d="M20.3496 8.69098L15.4147 13.6259C14.6336 14.4069 13.3673 14.4069 12.5863 13.6259L10.4147 11.4543C9.63365 10.6733 8.36733 10.6733 7.58628 11.4543L3.65137 15.3892"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />,
    <path
      key={2}
      d="M14.05 8.04017C14.05 8.06777 14.0276 8.09013 14 8.09011C13.9724 8.09009 13.95 8.06771 13.95 8.04011C13.95 8.01251 13.9724 7.99013 14 7.99011C14.0276 7.99009 14.05 8.01244 14.05 8.04004V8.04017"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />,
  ],
});

export const SearchIcon = createIcon({
  displayName: "Search",
  viewBox: "0 0 24 25",
  path: [
    <circle
      key={0}
      cx="11.059"
      cy="11.0987"
      fill="none"
      r="7.06194"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />,
    <path
      key={1}
      d="M20.0034 20.0434L16.0518 16.0918"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />,
  ],
});

export const SettingsIcon = createIcon({
  displayName: "Settings",
  viewBox: "0 0 25 25",
  path: [
    <path
      key={0}
      clipRule="evenodd"
      d="M11.2999 10.5541L14.2801 7.58387C14.2989 7.56507 14.3073 7.53825 14.3025 7.51209C14.2978 7.48593 14.2805 7.46377 14.2563 7.45278C12.5016 6.69493 10.4627 7.07473 9.09855 8.41355C7.7915 9.68695 7.36883 11.6166 8.0239 13.3198L3.55859 17.7732C2.82121 18.509 2.81921 19.703 3.55412 20.4413C4.28993 21.1787 5.48396 21.1807 6.22223 20.4458L10.7236 15.9594C12.4734 16.6139 14.445 16.1581 15.73 14.802C17.015 13.446 17.3641 11.4528 16.6165 9.74068C16.6049 9.71731 16.583 9.7008 16.5573 9.69622C16.5317 9.69164 16.5054 9.69952 16.4865 9.71745L13.4312 12.6916L12.3666 11.6204L11.2999 10.5541Z"
      fill="none"
      fillRule="evenodd"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />,
    <path
      key={1}
      d="M7.1107 19.561C10.9774 22.083 16.124 21.2799 19.0385 17.6997C21.953 14.1195 21.6953 8.917 18.4414 5.64232C15.1874 2.36764 9.98661 2.07697 6.38801 4.96868C2.78942 7.86039 1.95366 13.0018 4.45106 16.8844"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />,
  ],
});

export const TemplateIcon = createIcon({
  displayName: "Template",
  viewBox: "0 0 24 25",
  path: [
    <path
      key={0}
      clipRule="evenodd"
      d="M6.99776 3.03635H17.0019C19.212 3.03635 21.0036 4.82795 21.0036 7.03801V17.0422C21.0036 19.2522 19.212 21.0438 17.0019 21.0438H6.99776C4.7877 21.0438 2.99609 19.2522 2.99609 17.0422V7.03801C2.99609 4.82795 4.7877 3.03635 6.99776 3.03635Z"
      fill="none"
      fillRule="evenodd"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />,
    <path
      key={1}
      d="M12 12.0401H17.0021"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />,
    <path
      key={2}
      d="M15.001 7.03802H17.0018"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />,
    <path
      key={3}
      d="M6.99805 7.03802H12.0001"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />,
    <path
      key={4}
      d="M6.99805 12.0401H8.99888"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />,
    <path
      key={5}
      d="M6.99805 17.0422H12.0001"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />,
    <path
      key={6}
      d="M15.001 17.0422H17.0018"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />,
  ],
});

export const MoreIcon = createIcon({
  displayName: "More",
  viewBox: "0 0 26 26",
  path: [
    <path
      key={1}
      d="M12.9972 18.502C12.7221 18.502 12.497 18.727 12.5 19.0022C12.5 19.2773 12.7251 19.5024 13.0002 19.5024C13.2754 19.5024 13.5004 19.2773 13.5004 19.0022C13.5004 18.727 13.2754 18.502 12.9972 18.502"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />,
    <path
      key={2}
      d="M12.9972 12.5C12.7221 12.5 12.497 12.7251 12.5 13.0002C12.5 13.2753 12.7251 13.5004 13.0002 13.5004C13.2754 13.5004 13.5004 13.2753 13.5004 13.0002C13.5004 12.7251 13.2754 12.5 12.9972 12.5"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />,
    <path
      key={3}
      d="M12.9972 6.49707C12.7221 6.49707 12.497 6.72216 12.5 6.99728C12.5 7.27239 12.7251 7.49749 13.0002 7.49749C13.2754 7.49749 13.5004 7.27239 13.5004 6.99728C13.5004 6.72216 13.2754 6.49707 12.9972 6.49707"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />,
  ],
});

export const ViewIcon = createIcon({
  displayName: "View",
  viewBox: "0 0 24 24",
  path: [
    <path
      key={0}
      d="M14.123 9.88a3.004 3.004 0 0 1 0 4.245 3.004 3.004 0 0 1-4.245 0 3.004 3.004 0 0 1 0-4.245 3 3 0 0 1 4.245 0"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />,
    <path
      key={1}
      clipRule="evenodd"
      d="M3 12c0-.659.152-1.311.446-1.912v0C4.961 6.991 8.309 5 12 5s7.039 1.991 8.554 5.088v0c.294.601.446 1.253.446 1.912s-.152 1.311-.446 1.912v0C19.039 17.009 15.691 19 12 19s-7.039-1.991-8.554-5.088v0A4.346 4.346 0 0 1 3 12Z"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />,
  ],
});

export const EditIcon = createIcon({
  displayName: "Edit",
  viewBox: "0 0 24 24",
  path: [
    <path
      key={0}
      d="M13 21h4a3 3 0 0 0 3-3V8.51a3 3 0 0 0-.879-2.122l-2.509-2.51A3 3 0 0 0 14.491 3H7a3 3 0 0 0-3 3v4"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />,
    <path
      key={1}
      d="M20 8.5h-3.5a2 2 0 0 1-2-2V3"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />,
    <path
      key={2}
      clipRule="evenodd"
      d="m9.711 13.235-.946-.946a.986.986 0 0 0-1.395 0l-3.142 3.142a.78.78 0 0 0-.228.551v1.768c0 .138.112.25.25.25h1.767a.78.78 0 0 0 .552-.229l3.142-3.141a.986.986 0 0 0 0-1.396v0Z"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />,
    <path
      key={3}
      d="M9 21H4"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />,
  ],
});

export const DeleteIcon = createIcon({
  displayName: "Delete",
  viewBox: "0 0 24 24",
  path: [
    <path
      key={0}
      clipRule="evenodd"
      d="M16.546 22.004H9.46a2.25 2.25 0 0 1-2.244-2.078L6.25 7.373h13.506l-.966 12.553a2.25 2.25 0 0 1-2.244 2.078v0Z"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />,
    <path
      key={1}
      d="M21.003 7.373H4.996"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />,
    <path
      key={2}
      clipRule="evenodd"
      d="M10.188 3.996h5.627c.622 0 1.126.504 1.126 1.126v2.25H9.063v-2.25a1.125 1.125 0 0 1 1.125-1.126Z"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />,
    <path
      key={3}
      d="M11 18.002h4.002"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />,
  ],
});

export const CheckmarkIcon = createIcon({
  displayName: "Checkmark",
  viewBox: "0 0 24 24",
  path: (
    <path
      d="M18 8L9.75 16L6 12.3636"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />
  ),
});

export const SmallCrossIcon = createIcon({
  displayName: "SmallCrossIcon",
  viewBox: "0 0 24 24",
  path: [
    <path
      key={0}
      d="M8 8L16 16"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />,
    <path
      key={1}
      d="M16 8L8 16"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />,
  ],
});

export const GenerateTemplateIcon = createIcon({
  displayName: "GenerateTemplateIcon",
  viewBox: "0 0 24 25",
  path: [
    <path
      key={0}
      d="M18.0021 12.8752H7.99791"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />,
    <path
      key={1}
      d="M18.0021 8.99826H7.99791"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />,
    <rect
      key={2}
      fill="none"
      height="18.0075"
      rx="3"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      width="18.0075"
      x="3.99625"
      y="3.99609"
    />,
    <path
      key={3}
      d="M15.0008 16.001L16.0012 17.0014L15.0008 18.0018"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />,
    <path
      key={4}
      d="M8.99833 16.001L7.99791 17.0014L8.99833 18.0018"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />,
    <path
      key={5}
      d="M11.4994 18.5022L12.4998 15.501"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />,
  ],
});

export const SetEnvironmentVariableIcon = createIcon({
  displayName: "SetEnvironmentVariableIcon",
  viewBox: "0 0 24 24",
  path: [
    <path
      key={0}
      clipRule="evenodd"
      d="M15.375 16H4.625C3.727 16 3 15.273 3 14.375V4.625C3 3.727 3.727 3 4.625 3H15.375C16.273 3 17 3.727 17 4.625V14.375C17 15.273 16.273 16 15.375 16Z"
      fill="none"
      fillRule="evenodd"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />,
    <path
      key={1}
      d="M11 12H13"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />,
    <path
      key={2}
      d="M7 7L9 9L7 11"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />,
    <path
      key={3}
      d="M17 8H19.375C20.273 8 21 8.727 21 9.625V19.375C21 20.273 20.273 21 19.375 21H7.625C6.727 21 6 20.273 6 19.375V16"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />,
  ],
});

export const CloneIcon = createIcon({
  displayName: "cloneIcon",
  viewBox: "0 0 24 24",
  path: [
    <path
      key={1}
      d="M16 18H18C19.1046 18 20 17.1046 20 16V5C20 3.89543 19.1046 3 18 3H9C7.89543 3 7 3.89543 7 5V7"
      fill="none"
      fillRule="evenodd"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />,
    <path
      key={2}
      clipRule="evenodd"
      d="M15.3593 9.47041L13.5296 7.6407C13.1194 7.23047 12.563 7 11.9828 7H6C4.89543 7 4 7.89543 4 9V19C4 20.1046 4.89543 21 6 21H14C15.1046 21 16 20.1046 16 19V11.0172C16 10.437 15.7695 9.88065 15.3593 9.47041Z"
      fill="none"
      fillRule="evenodd"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />,
    <path
      key={3}
      d="M16 11.5H13C12.1716 11.5 11.5 10.8284 11.5 10V7"
      fill="none"
      fillRule="evenodd"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />,
  ],
});

export const PreferencesIcon = createIcon({
  displayName: "preferencesIcon",
  viewBox: "0 0 25 26",
  path: [
    <path
      key={1}
      d="M16.0002 7.99805V13.0001"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />,
    <rect
      key={2}
      fill="none"
      height="3.50146"
      rx="1"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      width="3.50146"
      x="14.25"
      y="13"
    />,
    <rect
      key={3}
      fill="none"
      height="3.50146"
      rx="1"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      width="3.50146"
      x="8.24219"
      y="9.49878"
    />,
    <path
      key={4}
      d="M16.0002 16.5015V18.0021"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />,
    <path
      key={5}
      d="M9.9924 18.0021V13"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />,
    <path
      key={6}
      d="M9.9924 9.49867V7.99805"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />,
    <rect
      key={7}
      fill="none"
      height="18.0075"
      rx="5"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      width="18.0075"
      x="3.99219"
      y="3.99634"
    />,
  ],
});

export const SettingsToggleIcon = createIcon({
  displayName: "SettingsToggleIcon",
  viewBox: "0 0 25 26",
  path: [
    <path
      key={0}
      d="M16.0002 7.99805V13.0001"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />,
    <rect
      key={1}
      fill="none"
      height="3.50146"
      rx="1"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      width="3.50146"
      x="14.25"
      y="13"
    />,
    <rect
      key={2}
      fill="none"
      height="3.50146"
      rx="1"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      width="3.50146"
      x="8.24219"
      y="9.49902"
    />,
    <path
      key={3}
      d="M16.0002 16.502V18.0026"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />,
    <path
      key={4}
      d="M9.9924 18.0021V13"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />,
    <path
      key={5}
      d="M9.9924 9.49867V7.99805"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />,
    <rect
      key={6}
      fill="none"
      height="18.0075"
      rx="5"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      width="18.0075"
      x="3.99219"
      y="3.99609"
    />,
  ],
});

export const MembersIcon = createIcon({
  displayName: "MembersIcon",
  viewBox: "0 0 24 24",
  path: [
    <path
      key={0}
      d="M8.9 7.6C9.7 8.4 9.7 9.6 8.9 10.4C8.1 11.2 6.9 11.2 6.1 10.4C5.3 9.6 5.3 8.4 6.1 7.6C6.9 6.8 8.1 6.8 8.9 7.6"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />,
    <path
      key={1}
      d="M17.7989 6.70039C18.7989 7.70039 18.7989 9.30039 17.7989 10.2004C16.7989 11.1004 15.1989 11.2004 14.2989 10.2004C13.3989 9.20039 13.2989 7.60039 14.2989 6.70039C15.1989 5.80039 16.7989 5.80039 17.7989 6.70039"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />,
    <path
      key={2}
      d="M20.5 18V17C20.5 15.3 19.2 14 17.5 14H14.5C12.8 14 11.5 15.3 11.5 17V18"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />,
    <path
      key={3}
      d="M8.5 14H6.5C4.8 14 3.5 15.3 3.5 17V18"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />,
  ],
});

export const SubscriptionIcon = createIcon({
  displayName: "SubscriptionIcon",
  viewBox: "0 0 25 26",
  path: [
    <path
      key={0}
      clipRule="evenodd"
      d="M17.7356 12.6259L13.2229 16.236C12.8902 16.5022 12.4106 16.4756 12.1093 16.1743L9.82304 13.888C9.52176 13.5868 9.4952 13.1071 9.76136 12.7744L13.3715 8.26178C15.0223 6.19829 17.5216 4.99707 20.1641 4.99707V4.99707C20.6259 4.99707 21.0003 5.37144 21.0003 5.83325V5.83325C21.0003 8.47581 19.7991 10.9751 17.7356 12.6259Z"
      fill="none"
      fillRule="evenodd"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />,
    <path
      key={1}
      d="M9.59102 13.4587L7.69676 12.8273C7.4668 12.7506 7.28141 12.578 7.18865 12.354C7.09589 12.1301 7.10487 11.8769 7.21328 11.6601L8.22335 9.63993C8.365 9.35666 8.65454 9.17773 8.97125 9.17773H12.6353"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />,
    <path
      key={2}
      d="M12.5391 16.4021L13.1707 18.297C13.2473 18.5269 13.42 18.7123 13.644 18.8051C13.8679 18.8979 14.1211 18.8889 14.3379 18.7805L16.358 17.7704C16.6413 17.6287 16.8202 17.3392 16.8202 17.0225V13.3584"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />,
    <path
      key={3}
      clipRule="evenodd"
      d="M7.60077 20.6114L4.99219 21.0033L5.38408 18.3947C5.51121 17.5484 6.17552 16.8841 7.02176 16.757V16.757C7.63325 16.6651 8.25205 16.8689 8.68928 17.3062C9.12651 17.7434 9.33029 18.3622 9.23843 18.9737V18.9737C9.11129 19.8199 8.447 20.4842 7.60077 20.6114V20.6114Z"
      fill="none"
      fillRule="evenodd"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />,
    <path
      key={4}
      d="M20.6509 8.26855C21.5362 9.68787 22.0043 11.3277 22.0015 13.0005C22 16.3178 20.1754 19.3659 17.2521 20.9342C14.3289 22.5024 10.7803 22.337 8.01562 20.5037"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />,
    <path
      key={5}
      d="M5.4916 17.9822C3.14681 14.4581 3.57451 9.77643 6.51903 6.73561C9.46354 3.69479 14.1291 3.11669 17.7267 5.34689"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />,
  ],
});

export const BuildSettingsIcon = createIcon({
  displayName: "BuildSettingsIcon",
  viewBox: "0 0 25 26",
  path: [
    <path
      key={0}
      d="M13.9408 15.304C14.9372 14.3076 14.9372 12.6929 13.9408 11.6965C12.9443 10.7001 11.3297 10.7001 10.3332 11.6965C9.33683 12.6929 9.33683 14.3076 10.3332 15.304C11.3287 16.3004 12.9443 16.3004 13.9408 15.304"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />,
    <path
      key={1}
      d="M18.5152 13.5006C18.5152 13.7817 18.4902 14.0618 18.4562 14.334L19.9568 15.5074C20.289 15.7686 20.38 16.2338 20.1689 16.5999L18.8344 18.9079C18.6233 19.273 18.1761 19.4271 17.7839 19.271L16.4434 18.7328C16.1773 18.6257 15.8801 18.6708 15.64 18.8288C15.433 18.9659 15.2189 19.0919 14.9968 19.203C14.7407 19.331 14.5536 19.5651 14.5126 19.8483L14.3085 21.2769C14.2485 21.694 13.8903 22.0042 13.4681 22.0042H10.807C10.3859 22.0042 10.0277 21.694 9.96768 21.2769L9.7616 19.8463C9.72058 19.5641 9.5345 19.331 9.2804 19.202C9.0573 19.0889 8.84222 18.9609 8.63413 18.8238C8.39503 18.6668 8.09891 18.6247 7.8338 18.7308L6.49124 19.27C6.09907 19.4271 5.65089 19.273 5.4408 18.9069L4.10624 16.5989C3.89415 16.2328 3.98519 15.7666 4.31833 15.5064L5.81896 14.333C5.78494 14.0608 5.75893 13.7807 5.75893 13.4996C5.75893 13.2195 5.78394 12.9384 5.81896 12.6663L4.31833 11.4928C3.98619 11.2317 3.89515 10.7665 4.10624 10.4003L5.4408 8.09236C5.65189 7.72721 6.09907 7.57314 6.49124 7.72921L7.8348 8.26843C8.10091 8.37648 8.39703 8.33446 8.63513 8.17639C8.84222 8.03834 9.05831 7.91228 9.2814 7.79824C9.5365 7.66918 9.72158 7.43709 9.7626 7.15397L9.96768 5.72437C10.0267 5.3072 10.3849 4.99707 10.806 4.99707H12.1376"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />,
    <path
      key={2}
      d="M16.0002 3.49609V8.99839"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />,
    <path
      key={3}
      d="M19.0002 3.49609V8.99839"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />,
    <path
      key={4}
      d="M22.0002 3.49609V8.99839"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />,
  ],
});

export const UserIcon = createIcon({
  displayName: "UserIcon",
  viewBox: "0 0 24 24",
  path: [
    <path
      key={0}
      d="M6.91108 18.4333C7.09928 15.714 9.36474 13.5666 12.1318 13.5666C14.8988 13.5666 17.1643 15.714 17.3525 18.4333H6.91108Z"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />,
    <path
      key={1}
      d="M15.0992 8.26659C15.0992 9.75776 13.8904 10.9666 12.3992 10.9666C10.908 10.9666 9.69922 9.75776 9.69922 8.26659C9.69922 6.77542 10.908 5.56659 12.3992 5.56659C13.8904 5.56659 15.0992 6.77542 15.0992 8.26659Z"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />,
  ],
});

export const TwoUsersIcon = createIcon({
  displayName: "TwoUsersIcon",
  viewBox: "0 0 24 24",
  path: [
    <path
      key={0}
      d="M11.7175 18.4334C11.9013 16.5989 13.4497 15.1667 15.3326 15.1667C17.2154 15.1667 18.7638 16.5989 18.9476 18.4334H11.7175Z"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeOpacity=".75"
      strokeWidth="1.5"
    />,
    <path
      key={1}
      d="M5.0478 18.4333C5.236 15.7141 7.50146 13.5667 10.2685 13.5667C13.0355 13.5667 15.301 15.7141 15.4892 18.4333H5.0478Z"
      fill="white"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />,
    <path
      key={2}
      d="M13.0992 8.26665C13.0992 9.75782 11.8904 10.9667 10.3992 10.9667C8.90805 10.9667 7.69922 9.75782 7.69922 8.26665C7.69922 6.77548 8.90805 5.56665 10.3992 5.56665C11.8904 5.56665 13.0992 6.77548 13.0992 8.26665Z"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />,
    <path
      key={3}
      d="M17.1005 11.2C17.1005 11.9548 16.4886 12.5666 15.7339 12.5666C14.9791 12.5666 14.3672 11.9548 14.3672 11.2C14.3672 10.4452 14.9791 9.83331 15.7339 9.83331C16.4886 9.83331 17.1005 10.4452 17.1005 11.2Z"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeOpacity=".75"
      strokeWidth="1.5"
    />,
  ],
});

export const ThreeUsersIcon = createIcon({
  displayName: "ThreeUsersIcon",
  viewBox: "0 0 24 24",
  path: [
    <path
      key={0}
      d="M2.31905 18.4334C2.50289 16.5989 4.05125 15.1667 5.93411 15.1667C7.81698 15.1667 9.36534 16.5989 9.54918 18.4334H2.31905Z"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeOpacity=".75"
      strokeWidth="1.5"
    />,
    <path
      key={1}
      d="M12.736 18.4334C12.9806 16.5894 14.559 15.1667 16.4698 15.1667H16.6809C18.4899 15.1667 19.9578 16.6272 19.9697 18.4334H12.736Z"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeOpacity=".75"
      strokeWidth="1.5"
    />,
    <path
      key={2}
      d="M6.03999 18.4331C6.22819 15.7139 8.49365 13.5664 11.2607 13.5664C14.0277 13.5664 16.2932 15.7139 16.4814 18.4331H6.03999Z"
      fill="white"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />,
    <path
      key={3}
      d="M14.0992 8.26671C14.0992 9.75788 12.8904 10.9667 11.3992 10.9667C9.90805 10.9667 8.69922 9.75788 8.69922 8.26671C8.69922 6.77554 9.90805 5.56671 11.3992 5.56671C12.8904 5.56671 14.0992 6.77554 14.0992 8.26671Z"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />,
    <path
      key={4}
      d="M18.0966 11.2C18.0966 11.9548 17.4847 12.5666 16.7299 12.5666C15.9752 12.5666 15.3633 11.9548 15.3633 11.2C15.3633 10.4452 15.9752 9.83331 16.7299 9.83331C17.4847 9.83331 18.0966 10.4452 18.0966 11.2Z"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeOpacity=".75"
      strokeWidth="1.5"
    />,
    <path
      key={5}
      d="M7.43255 11.2C7.43255 11.9548 6.82067 12.5666 6.06589 12.5666C5.3111 12.5666 4.69922 11.9548 4.69922 11.2C4.69922 10.4452 5.3111 9.83331 6.06589 9.83331C6.82067 9.83331 7.43255 10.4452 7.43255 11.2Z"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeOpacity=".75"
      strokeWidth="1.5"
    />,
  ],
});

export const VerifyEmailIcon = createIcon({
  displayName: "VerifyEmailIcon",
  viewBox: "0 0 30 30",
  path: [
    <path
      key={0}
      d="M16.3346 24.3346H5.66797C3.45883 24.3346 1.66797 22.5438 1.66797 20.3346V9.66797C1.66797 7.45883 3.45883 5.66797 5.66797 5.66797H16.3346"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />,
    <path
      key={1}
      d="M20.3359 24.3346H24.3359C26.5451 24.3346 28.3359 22.5438 28.3359 20.3346V9.66797C28.3359 7.45883 26.5451 5.66797 24.3359 5.66797H20.3359"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />,
    <path
      key={2}
      d="M20.3346 1.66797V28.3346"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />,
    <rect
      key={3}
      fill="none"
      height="5.33333"
      rx="1.25"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      width="8"
      x="7"
      y="14.3359"
    />,
    <path
      key={4}
      d="M13.3346 14.3359V12.6693C13.3346 11.3806 12.29 10.3359 11.0013 10.3359V10.3359C9.71264 10.3359 8.66797 11.3806 8.66797 12.6693V14.3359"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />,
  ],
});

export const EmailIcon = createIcon({
  displayName: "EmailIcon",
  viewBox: "0 0 32 32",
  path: [
    <path
      key={0}
      clipRule="evenodd"
      d="M13.9752 24.9463L28.6246 17.1703C29.5699 16.6689 29.5699 15.3196 28.6246 14.8169L13.9966 7.05293C12.8926 6.46626 11.6552 7.57293 12.1232 8.72759L15.0419 15.9276L12.0992 23.2743C11.6379 24.4303 12.8739 25.5303 13.9752 24.9463Z"
      fill="none"
      fillRule="evenodd"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />,
    <path
      key={1}
      d="M15.043 15.9341L29.3363 16.0008"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />,
    <path
      key={2}
      d="M5.33594 19.9987H7.46927"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />,
    <path
      key={3}
      d="M4 15.9987H7.46667"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />,
    <path
      key={4}
      d="M2.66797 11.9987H7.46797"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />,
  ],
});

export const DotIcon = createIcon({
  displayName: "DotIcon",
  viewBox: "0 0 24 24",
  path: [<circle key={0} cx="12" cy="12" fill="currentColor" r="4" xmlns="http://www.w3.org/2000/svg" />],
});

export const LogoutIcon = createIcon({
  displayName: "Logout",
  viewBox: "0 0 24 24",
  path: [
    <path
      key={0}
      clipRule="evenodd"
      d="M14.163 21.0001L18.607 20.0121C19.421 19.8321 20 19.1101 20 18.2771V5.76614C20 4.93314 19.421 4.21114 18.608 4.03114L14.164 3.04314C13.053 2.79614 12 3.64114 12 4.77914V19.2651C12 20.4021 13.053 21.2471 14.163 21.0001V21.0001Z"
      fill="none"
      fillRule="evenodd"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />,
    <path
      key={1}
      d="M15 10.9805V12.9805"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />,
    <path
      key={2}
      d="M4 16V18.042C4 19.147 4.895 20.042 6 20.042H9"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />,
    <path
      key={3}
      d="M4 8V6C4 4.895 4.895 4 6 4H9"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />,
    <path
      key={4}
      d="M4 12H9"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />,
    <path
      key={5}
      d="M7 14L9 12L7 10"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />,
  ],
});

export const ToggleHiddenIcon = createIcon({
  displayName: "ToggleHiddenIcon",
  viewBox: "0 0 26 26",
  path: [
    <path
      key={0}
      d="M15.5582 14.5577C14.9186 15.6361 13.6764 16.2036 12.4426 15.9811C11.2087 15.7585 10.243 14.7928 10.0205 13.559C9.79795 12.3251 10.3654 11.0829 11.4438 10.4434"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />,
    <path
      key={1}
      d="M18.9964 17.9946C17.2716 19.3028 15.1646 20.0079 12.9998 20.0014C9.41273 20.0652 6.09837 18.0938 4.44226 14.9112C3.84737 13.7054 3.84737 12.2915 4.44226 11.0856C5.27051 9.43626 6.59081 8.085 8.22056 7.21875"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />,
    <path
      key={2}
      d="M21.4287 15.1331C21.4689 15.057 21.5211 14.9888 21.559 14.9114C22.1539 13.7055 22.1539 12.2916 21.559 11.0858C19.9029 7.90318 16.5885 5.93177 13.0014 5.99565C12.7766 5.99565 12.5579 6.02566 12.3359 6.04029"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />,
    <path
      key={3}
      d="M22.0032 21.0032L4.99609 3.99609"
      stroke="#9A2F8F"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />,
  ],
});

export const BugIcon = createIcon({
  displayName: "BugIcon",
  viewBox: "0 0 32 32",
  path: [
    <path
      key={0}
      d="M27.7297 6.93311L25.6723 10.9555L22.9297 12.7998"
      fill="none"
      stroke="currentColor"
      strokeWidth="1.6"
    />,
    <path key={1} d="M2.13281 16.5332H8.53281" fill="none" stroke="currentColor" strokeWidth="1.6" />,
    <path key={2} d="M23.4688 16.5332H29.8688" fill="none" stroke="currentColor" strokeWidth="1.6" />,
    <path
      key={3}
      d="M4.26562 6.93311L6.32306 10.9555L9.06563 12.7998"
      fill="none"
      stroke="currentColor"
      strokeWidth="1.6"
    />,
    <path
      key={4}
      d="M16.8984 26.1333H15.1089C11.7736 26.1333 9.07031 23.4473 9.07031 20.1333V14.1333C9.07031 11.9233 10.8718 10.1333 13.0961 10.1333H18.9112C21.1355 10.1333 22.937 11.9233 22.937 14.1333V20.1333C22.937 23.4473 20.2337 26.1333 16.8984 26.1333Z"
      fill="none"
      stroke="currentColor"
      strokeWidth="1.6"
    />,
    <path
      key={5}
      d="M4.26562 26.6666L6.32306 22.2785L9.06563 20.2666"
      fill="none"
      stroke="currentColor"
      strokeWidth="1.6"
    />,
    <path
      key={6}
      d="M27.7297 26.6666L25.6723 22.2785L22.9297 20.2666"
      fill="none"
      stroke="currentColor"
      strokeWidth="1.6"
    />,
    <path
      key={7}
      d="M19.1969 10.1335V8.53349C19.1969 6.76618 17.7642 5.3335 15.9969 5.3335V5.3335C14.2296 5.3335 12.7969 6.76618 12.7969 8.53349V10.1335"
      fill="none"
      stroke="currentColor"
      strokeWidth="1.6"
    />,
  ],
});

export const ChartIcon = createIcon({
  displayName: "ChartIcon",
  viewBox: "0 0 32 32",
  path: [
    <path
      key={0}
      d="M17.5 19.7493C17.5 18.508 18.5067 17.5 19.7493 17.5C20.992 17.5 21.9987 18.5067 21.9987 19.7493C21.9987 20.992 20.992 21.9987 19.7493 21.9987C18.5107 22.004 17.504 21.0053 17.5 19.7667C17.5 19.7613 17.5 19.756 17.5 19.7493Z"
      fill="none"
      stroke="currentColor"
      strokeWidth="1.6"
    />,
    <path key={1} d="M18.1637 18.1603L13.8438 13.8403" fill="none" stroke="currentColor" strokeWidth="1.6" />,
    <path key={2} d="M21.3438 18.1603L25.6504 13.8403" fill="none" stroke="currentColor" strokeWidth="1.6" />,
    <path
      key={3}
      d="M14.5 12.2507C14.5 13.492 13.4933 14.5 12.2507 14.5C11.008 14.5 10 13.4934 10 12.2507C10 11.0067 11.0067 10 12.2507 10C13.4893 9.99469 14.496 10.9934 14.5 12.232C14.5 12.2387 14.5 12.244 14.5 12.2507Z"
      fill="none"
      stroke="currentColor"
      strokeWidth="1.6"
    />,
    <path key={4} d="M6.34375 18.1603L10.6504 13.8403" stroke="currentColor" strokeWidth="1.6" />,
    <path
      key={5}
      d="M29.5 12.2508C29.5 13.4922 28.4933 14.5002 27.2507 14.5002C26.008 14.5002 25 13.4935 25 12.2508C25 11.0082 26.0067 10.0015 27.2493 10.0015C28.488 9.99615 29.4947 10.9948 29.4987 12.2335C29.5 12.2388 29.5 12.2442 29.5 12.2508Z"
      fill="none"
      stroke="currentColor"
      strokeWidth="1.6"
    />,
    <path
      key={6}
      d="M2.5 19.7493C2.5 18.508 3.50667 17.5 4.74933 17.5C5.992 17.5 7 18.5067 7 19.7493C7 20.992 5.99333 21.9987 4.75067 21.9987C3.512 22.004 2.50533 21.0053 2.50133 19.7667C2.5 19.7613 2.5 19.756 2.5 19.7493Z"
      fill="none"
      stroke="currentColor"
      strokeWidth="1.6"
    />,
    <path
      key={7}
      d="M4.11867 17.6013C4.04933 17.076 4 16.544 4 16C4 9.372 9.372 4 16 4C20.5 4 24.4173 6.48133 26.4707 10.1467"
      fill="none"
      stroke="currentColor"
      strokeWidth="1.6"
    />,
    <path
      key={8}
      d="M5.53125 21.8536C7.58458 25.5189 11.5019 28.0003 16.0019 28.0003C22.6299 28.0003 28.0019 22.6283 28.0019 16.0003C28.0019 15.4563 27.9526 14.9243 27.8832 14.3989"
      fill="none"
      stroke="currentColor"
      strokeWidth="1.6"
    />,
  ],
});

export const PingPongIcon = createIcon({
  displayName: "PingPongIcon",
  viewBox: "0 0 32 32",
  path: [
    <path
      key={0}
      d="M12.2961 22.2961L10.2601 20.2601C9.76942 19.7694 8.98542 19.7374 8.45609 20.1854L4.37075 23.6414C3.77475 24.1454 3.73742 25.0508 4.28942 25.6014L6.95342 28.2668C7.50542 28.8188 8.41075 28.7801 8.91342 28.1854L12.3708 24.1001C12.8188 23.5708 12.7868 22.7868 12.2961 22.2961Z"
      fill="none"
      stroke="currentColor"
      strokeWidth="1.6"
    />,
    <path
      key={1}
      d="M10.4805 9.26683C7.69918 12.9055 8.03918 18.0402 11.2779 21.2788C14.5165 24.5175 19.6512 24.8588 23.2899 22.0762C24.3899 21.2348 25.3725 20.2522 26.2139 19.1522L26.2165 19.1482C28.9979 15.5095 28.6579 10.3748 25.4192 7.13617C22.7899 4.50683 18.9205 3.82416 15.6072 5.13483"
      fill="none"
      stroke="currentColor"
      strokeWidth="1.6"
    />,
    <path key={2} d="M8.96094 12.3467L20.2143 23.6" fill="none" stroke="currentColor" strokeWidth="1.6" />,
    <path
      key={3}
      clip-rule="evenodd"
      d="M15.0263 4.30981C16.328 5.61156 16.328 7.7221 15.0263 9.02385C13.7245 10.3256 11.614 10.3256 10.3122 9.02385C9.0105 7.7221 9.0105 5.61155 10.3122 4.30981C11.614 3.00806 13.7245 3.00806 15.0263 4.30981Z"
      fill="none"
      stroke="currentColor"
      strokeWidth="1.6"
    />,
  ],
});

export const ReleaseLogoIcon = createIcon({
  displayName: "ReleaseLogoIcon",
  viewBox: "0 0 37 32",
  path: [
    <path
      key={0}
      d="M21.2216 30.2155L35.719 5.3487C37.1047 2.97165 35.3726 0 32.6006 0H3.60621C0.834227 0 -0.898175 2.97165 0.487815 5.3487L3.03876 9.72414L4.00489 11.3815C5.46706 13.8894 8.69956 14.7488 11.2293 13.3023L15.5769 10.8164C15.9042 10.6293 16.0162 10.214 15.8273 9.88957L14.5066 7.62405C13.9811 6.723 14.6364 5.59549 15.6855 5.59549H26.6853C27.7358 5.59549 28.3921 6.72469 27.866 7.6271L22.3699 17.055C21.8437 17.9574 20.5305 17.9574 20.0044 17.055L18.6826 14.7874C18.4941 14.4637 18.0766 14.3528 17.75 14.5393L13.4004 17.0261C10.873 18.4713 10.0073 21.6772 11.4678 24.1827L12.4339 25.8401L14.9849 30.2155C16.3726 32.5949 19.8346 32.5949 21.2216 30.2155Z"
      fill="none"
      stroke="currentColor"
      strokeWidth="1.6"
    />,
  ],
});

export const ReleaseLogoIconFilled = createIcon({
  displayName: "ReleaseLogoIcon",
  viewBox: "0 0 37 32",
  path: [
    <path
      key={0}
      d="M21.2216 30.2155L35.719 5.3487C37.1047 2.97165 35.3726 0 32.6006 0H3.60621C0.834227 0 -0.898175 2.97165 0.487815 5.3487L3.03876 9.72414L4.00489 11.3815C5.46706 13.8894 8.69956 14.7488 11.2293 13.3023L15.5769 10.8164C15.9042 10.6293 16.0162 10.214 15.8273 9.88957L14.5066 7.62405C13.9811 6.723 14.6364 5.59549 15.6855 5.59549H26.6853C27.7358 5.59549 28.3921 6.72469 27.866 7.6271L22.3699 17.055C21.8437 17.9574 20.5305 17.9574 20.0044 17.055L18.6826 14.7874C18.4941 14.4637 18.0766 14.3528 17.75 14.5393L13.4004 17.0261C10.873 18.4713 10.0073 21.6772 11.4678 24.1827L12.4339 25.8401L14.9849 30.2155C16.3726 32.5949 19.8346 32.5949 21.2216 30.2155Z"
      fill="currentColor"
      stroke="currentColor"
      strokeWidth="1.6"
    />,
  ],
});

export const ReleaseLogomark = createIcon({
  displayName: "ReleaseLogomark",
  viewBox: "0 0 112.5 100",
  path: [
    <g key={0} transform="translate(55.1 -48.614)">
      <path
        d="m10.839 143.04 45.045-77.708c4.3058-7.4285-1.0761-16.715-9.6891-16.715h-90.09c-8.613 0-13.996 9.2862-9.6891 16.715l10.928 18.852c4.5434 7.8374 14.587 10.523 22.447 6.0023l13.508-7.7684c1.0172-0.58424 1.3652-1.8825 0.77831-2.8958l-4.1037-7.0798c-1.6329-2.8161 0.40355-6.3396 3.6634-6.3396h34.178c3.2638 0 5.303 3.5289 3.6687 6.349l-17.077 29.462c-1.6349 2.8195-5.7152 2.8195-7.3495 0l-4.1071-7.0865c-0.58625-1.0119-1.8832-1.3579-2.8978-0.77564l-13.515 7.7718c-7.8528 4.516-10.543 14.534-6.005 22.364l3.0022 5.1792 7.9257 13.673c4.3119 7.4358 15.069 7.4358 19.379 0z"
        fill="currentColor"
      />
    </g>,
  ],
});

export const ReleaseAiLogoIcon = createIcon({
  displayName: "ReleaseAiLogoIcon",
  viewBox: "0 0 217 193",
  path: [
    <path
      key={0}
      d="M126.603 182.237L213.091 32.2593C221.357 17.9227 211.024 0 194.487 0H21.5137C4.97679 0 -5.35829 17.9227 2.91018 32.2593L18.1285 58.6486L23.8922 68.6445C32.6151 83.7702 51.8994 88.9532 66.9913 80.229L92.9278 65.2362C94.8803 64.1076 95.5488 61.603 94.4218 59.6463L86.5426 45.9824C83.408 40.548 87.317 33.7477 93.576 33.7477H159.198C165.465 33.7477 169.38 40.5582 166.241 46.0008L133.453 102.862C130.314 108.305 122.48 108.305 119.341 102.862L111.456 89.1863C110.331 87.2337 107.84 86.5652 105.892 87.6897L79.9433 102.689C64.8656 111.405 59.7011 130.74 68.4139 145.852L74.1776 155.848L89.3959 182.237C97.6745 196.588 118.328 196.588 126.603 182.237Z"
      fill="currentColor"
    />,
    <path
      key={1}
      d="M186.862 185.894C184.679 190.274 178.922 193 172.271 193C161.651 193 155 187.354 155 178.789C155 170.028 160.857 165.261 171.476 164.19L179.914 163.314C185.076 162.729 186.763 161.562 186.763 158.739C186.763 154.651 184.282 152.412 178.028 152.412C171.478 152.412 168.897 154.748 168.5 158.836H155.993C156.589 148.714 163.437 142.581 177.83 142.581C191.825 142.581 199.27 148.421 199.27 158.934V191.832H186.862V185.894ZM174.157 172.267C169.691 172.657 167.408 174.701 167.408 177.913C167.408 181.028 170.187 183.17 175.05 183.17C181.999 183.17 186.762 178.887 186.762 173.436V169.25C185.969 170.517 183.685 171.393 180.409 171.684L174.157 172.267Z"
      fill="currentColor"
    />,
    <path key={2} d="M216.488 192.998V145.331H202.434V192.998H216.488Z" fill="currentColor" />,
    <path
      key={3}
      d="M216.488 139V130C216.488 128.895 215.593 128 214.488 128H204.434C203.329 128 202.434 128.895 202.434 130V139C202.434 140.105 203.329 141 204.434 141H214.488C215.593 141 216.488 140.105 216.488 139Z"
      fill="currentColor"
    />,
  ],
});

export const BranchIcon = createIcon({
  displayName: "BranchIcon",
  viewBox: "0 0 18 18",
  path: [
    <circle
      key={0}
      cx="13.3365"
      cy="5.33191"
      fill="none"
      r="1.66736"
      stroke="#A09CA8"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      transform="rotate(90 13.3365 5.33191)"
    />,
    <ellipse
      key={1}
      cx="13.3365"
      cy="12.6683"
      fill="none"
      rx="1.66736"
      ry="1.66736"
      stroke="#A09CA8"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      transform="rotate(90 13.3365 12.6683)"
    />,
    <ellipse
      key={2}
      cx="4.66467"
      cy="5.33191"
      fill="none"
      rx="1.66736"
      ry="1.66736"
      stroke="#A09CA8"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      transform="rotate(90 4.66467 5.33191)"
    />,
    <path
      key={3}
      d="M11.668 5.33152L6.33241 5.33152"
      stroke="#A09CA8"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />,
    <path
      key={4}
      d="M6.33241 5.33154L7.6663 5.33154C8.58716 5.33154 9.33366 6.07805 9.33366 6.9989L9.33366 11.0006C9.33366 11.4428 9.50933 11.8669 9.82202 12.1796C10.1347 12.4923 10.5588 12.6679 11.001 12.6679L11.668 12.6679"
      fill="none"
      stroke="#A09CA8"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />,
  ],
});

export const UserResendIcon = createIcon({
  displayName: "UserResendIcon",
  viewBox: "0 0 40 40",
  path: [
    <ellipse
      key={0}
      cx="20.0003"
      cy="12.0794"
      fill="none"
      rx="7.08628"
      ry="7.08629"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2.5"
    />,
    <path
      key={1}
      d="M6.66016 31.6711V31.6711C6.66016 27.5272 10.0194 24.168 14.1633 24.168H18.4701"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2.5"
    />,
    <path
      key={2}
      d="M32.083 28.9033L32.9433 28.043C33.6622 29.7294 33.4009 31.674 32.2624 33.1109C31.1239 34.5478 29.2905 35.2468 27.4844 34.9325"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2.5"
    />,
    <path
      key={3}
      d="M24.56 31.1005L23.6997 31.9609C22.958 30.2262 23.2555 28.2217 24.4689 26.7771C25.6824 25.3325 27.6054 24.6936 29.4421 25.1247"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2.5"
    />,
  ],
});

export const ExternalLinkIcon = createIcon({
  displayName: "ExternalLinkIcon",
  viewBox: "0 0 16 16",
  path: [
    <path
      key={0}
      d="M12.6667 5.9987V3.33203H10"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />,
    <path
      key={1}
      d="M8.66797 7.33203L12.668 3.33203"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />,
    <path
      key={2}
      d="M13.3346 8.66797V10.0013C13.3346 11.8423 11.8423 13.3346 10.0013 13.3346H6.0013C4.16035 13.3346 2.66797 11.8423 2.66797 10.0013V6.0013C2.66797 4.16035 4.16035 2.66797 6.0013 2.66797H7.33464"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />,
  ],
});

export const NewWindowIcon = createIcon({
  displayName: "NewWindowIcon",
  viewBox: "0 0 16 16",
  path: [
    <path
      key={0}
      d="M9 9.667V7H6.667"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.4}
    />,
    <path
      key={1}
      d="M4 12L9 7"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.4}
    />,
    <path
      key={2}
      d="M2.66797 7.33464V6.0013C2.66797 4.16035 4.16035 2.66797 6.0013 2.66797H10.0013C11.8423 2.66797 13.3346 4.16035 13.3346 6.0013V10.0013C13.3346 11.8423 11.8423 13.3346 10.0013 13.3346H8.66797"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.4}
    />,
  ],
});

export const CloseIcon = createIcon({
  displayName: "CloseIcon",
  viewBox: "0 0 24 24",
  path: [
    <path
      key={0}
      d="M5.72 5.72a.75.75 0 0 1 1.06 0L12 10.94l5.22-5.22a.749.749 0 0 1 1.275.326.749.749 0 0 1-.215.734L13.06 12l5.22 5.22a.749.749 0 0 1-.326 1.275.749.749 0 0 1-.734-.215L12 13.06l-5.22 5.22a.751.751 0 0 1-1.042-.018.751.751 0 0 1-.018-1.042L10.94 12 5.72 6.78a.75.75 0 0 1 0-1.06Z"
      fill="currentColor"
      stroke="currentColor"
      strokeWidth={1.4}
    />,
  ],
});

export const PipelineIcon = createIcon({
  displayName: "PipelineIcon",
  viewBox: "0 0 48 48",
  path: [
    <circle
      key={0}
      cx="7"
      cy="7"
      fill="currentColor"
      id="path1"
      r="5"
      stroke="currentColor"
      strokeWidth="0.768754"
    />,
    <circle
      key={1}
      cx="40"
      cy="7"
      fill="currentColor"
      id="path1-2"
      r="5"
      stroke="currentColor"
      strokeWidth="1.43618"
    />,
    <circle
      key={2}
      cx="24"
      cy="24"
      fill="currentColor"
      id="path1-2-7"
      r="5"
      stroke="currentColor"
      strokeWidth="1.43618"
    />,
    <circle
      key={3}
      cx="7"
      cy="40"
      fill="currentColor"
      id="path1-2-6"
      r="5"
      stroke="currentColor"
      strokeWidth="1.43618"
    />,
    <circle
      key={4}
      cx="40"
      cy="40"
      fill="currentColor"
      id="path1-5"
      r="5"
      stroke="currentColor"
      strokeWidth="1.43618"
    />,
    <path
      key={5}
      d="M 7,7 H 40 V 23 H 7 V 40 H 40"
      fill="none"
      id="path14"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="4.28872"
    />,
  ],
});

export const PricingIcon1 = createIcon({
  displayName: "PricingIcon1",
  viewBox: "0 0 20 20",
  path: [
    <path
      key={0}
      d="M9.375 5.625C10.4105 5.625 11.25 4.78553 11.25 3.75C11.25 2.71447 10.4105 1.875 9.375 1.875C8.33947 1.875 7.5 2.71447 7.5 3.75C7.5 4.78553 8.33947 5.625 9.375 5.625Z"
      fill="none"
      stroke="#29063E"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    <path
      key={1}
      d="M3.125 11.25C4.16053 11.25 5 10.4105 5 9.375C5 8.33947 4.16053 7.5 3.125 7.5C2.08947 7.5 1.25 8.33947 1.25 9.375C1.25 10.4105 2.08947 11.25 3.125 11.25Z"
      fill="none"
      stroke="#29063E"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    <path
      key={2}
      d="M12.5 18.125C13.5355 18.125 14.375 17.2855 14.375 16.25C14.375 15.2145 13.5355 14.375 12.5 14.375C11.4645 14.375 10.625 15.2145 10.625 16.25C10.625 17.2855 11.4645 18.125 12.5 18.125Z"
      fill="none"
      stroke="#29063E"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    <path
      key={3}
      d="M16.25 7.5C17.2855 7.5 18.125 6.66053 18.125 5.625C18.125 4.58947 17.2855 3.75 16.25 3.75C15.2145 3.75 14.375 4.58947 14.375 5.625C14.375 6.66053 15.2145 7.5 16.25 7.5Z"
      fill="none"
      stroke="#29063E"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    <path
      key={4}
      d="M14.4453 5.13223L11.1875 4.24316"
      fill="none"
      stroke="#29063E"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    <path
      key={5}
      d="M7.97812 5.00488L4.51562 8.12051"
      fill="none"
      stroke="#29063E"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    <path
      key={6}
      d="M4.64062 10.4834L10.9906 15.1412"
      fill="none"
      stroke="#29063E"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    <path
      key={7}
      d="M13.125 14.4811L15.6266 7.39355"
      fill="none"
      stroke="#29063E"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
  ],
});

export const PricingIcon2 = createIcon({
  displayName: "PricingIcon2",
  viewBox: "0 0 20 20",
  path: [
    <path
      key={0}
      d="M16.25 3.125H13.75C13.4048 3.125 13.125 3.40482 13.125 3.75V6.25C13.125 6.59518 13.4048 6.875 13.75 6.875H16.25C16.5952 6.875 16.875 6.59518 16.875 6.25V3.75C16.875 3.40482 16.5952 3.125 16.25 3.125Z"
      fill="none"
      stroke="#29063E"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    <path
      key={1}
      d="M6.25 3.125H3.75C3.40482 3.125 3.125 3.40482 3.125 3.75V6.25C3.125 6.59518 3.40482 6.875 3.75 6.875H6.25C6.59518 6.875 6.875 6.59518 6.875 6.25V3.75C6.875 3.40482 6.59518 3.125 6.25 3.125Z"
      fill="none"
      stroke="#29063E"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    <path
      key={2}
      d="M16.25 13.125H13.75C13.4048 13.125 13.125 13.4048 13.125 13.75V16.25C13.125 16.5952 13.4048 16.875 13.75 16.875H16.25C16.5952 16.875 16.875 16.5952 16.875 16.25V13.75C16.875 13.4048 16.5952 13.125 16.25 13.125Z"
      fill="none"
      stroke="#29063E"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    <path
      key={3}
      d="M6.25 13.125H3.75C3.40482 13.125 3.125 13.4048 3.125 13.75V16.25C3.125 16.5952 3.40482 16.875 3.75 16.875H6.25C6.59518 16.875 6.875 16.5952 6.875 16.25V13.75C6.875 13.4048 6.59518 13.125 6.25 13.125Z"
      fill="none"
      stroke="#29063E"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    <path
      key={4}
      d="M5 13.125V6.875"
      fill="none"
      stroke="#29063E"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    <path
      key={5}
      d="M13.125 15H6.875"
      fill="none"
      stroke="#29063E"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    <path
      key={6}
      d="M15 6.875V13.125"
      fill="none"
      stroke="#29063E"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    <path
      key={7}
      d="M6.875 5H13.125"
      fill="none"
      stroke="#29063E"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
  ],
});
