import { useMemo } from "react";

import { Icon, Image, ImageProps, Link, useColorModeValue } from "@chakra-ui/react";

import { Product } from "@/__generated__/graphql";
import { ReactComponent as ReleaseAiLogo } from "@/assets/images/logos/ai-logo.svg";
import { ReactComponent as InstantDatasetsLogo } from "@/assets/images/logos/instant-datasets-logo.svg";
import { ReactComponent as ReleaseLogo } from "@/assets/images/logos/release-logo.svg";
import { useWorkspace } from "@/providers/useWorkspace";

import { ReleaseLogomark } from "./ReleaseIcons";

export default function Logo({
  collapsed,
  product: propsProduct,
  ...props
}: { collapsed?: boolean; product?: Product } & ImageProps) {
  const { product: workspaceProduct } = useWorkspace();
  const fillColor = useColorModeValue("violet.500", "gray.300");

  const product = propsProduct ?? workspaceProduct;

  const logoComponent = useMemo(() => {
    switch (product) {
      case Product.ReleaseAi: {
        return (
          <Image
            alt="Release AI Logo"
            as={ReleaseAiLogo}
            fill={fillColor}
            h={10}
            overflow="visible"
            width="auto"
            {...props}
          />
        );
      }
      case Product.StandaloneDatasets: {
        return (
          <Image
            alt="Release Instant Datasets Logo"
            as={InstantDatasetsLogo}
            fill={fillColor}
            h={10}
            overflow="visible"
            width="auto"
            {...props}
          />
        );
      }
      case Product.Release:
      default:
        return <Image alt="Release Logo" as={ReleaseLogo} fill={fillColor} width="auto" {...props} />;
    }
  }, [product, fillColor, props]);

  const logoItem = collapsed ? (
    <Icon as={ReleaseLogomark} color={fillColor} h={7} width="auto" />
  ) : (
    logoComponent
  );
  return (
    <Link aria-label="home" href="/home">
      {logoItem}
    </Link>
  );
}
